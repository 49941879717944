<template>
  <v-col class="my-0 py-0" cols="12">
    <!--  -->
    <div class="mx-0 mb-2">
      <div class="ml-n4 mr-n8 mx-md-n6" :class="color">
        <v-card class="transparent px-4 px-md-6 py-2" width="100%" flat tile>
          <slot></slot>
        </v-card>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "LimeLoansInnerCard",

  props: {
    color: {
      default: "background",
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
